<template>
  <div class="top">
    <div class="header">
      <div class="headerimage" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <!--        <div>-->
        <!--          <img src="@/assets/images/pleisure/pleisure_headerimage.jpg" />-->
        <!--        </div>-->
        <div class="col-lg-12 rowSpace"></div>
        <div class="text-center col-lg-12 mainTitle">프라이빗 럭셔리요트</div>
        <div class="mainTitleLine">
          <hr />
        </div>
        <div class="col-lg-12 text-center mainIntro">제주 최고의 요트에서 즐기는 스페셜 럭셔리 제주 여행</div>
        <div class="rowSpace"></div>
        <div class="col buttonArea">
          <router-link to="/p/Pleisure" class="prev-button">이전 </router-link>
          <router-link to="/p/Reservpleisure/0" class="resserve-button"> 예약신청 </router-link>
        </div>
        <div class="rowSpace"><hr /></div>
        <GallerySlideCarousels :slides="main_slides" :slide-count="4" :thumb-slide-width="60" />
      </div>
    </div>
    <div class="container subContainer">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 mainInfo">
          <div>제주 최고의 요트 위에서 아름다운 바다를 느껴보세요.</div>
          <div>아름다운 제주 자연의 신비와 요트가 만들어낸 물살을 유영하는 돌고래의 모습</div>
          <div>제주 최남단 송악산의 아름다운 절경 등 색다른 아름다움을 느끼실 수 있습니다.</div>
          <div>
            프라이빗한 선상 와인 파티와 함께 소중한 사람들과 제주 바다의 낭만과 재미, 프라이빗한 여유를 즐겨보세요.
          </div>
          <div>※ 요트의 종류에 따라 체험 내용 및 코스는 상이합니다.</div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">공통 의전 및 서비스</div>
          <div class="subDatail">
            <div>- 차량 의전 서비스 (벤츠 스프린터 / 카니발 하이리무진)</div>
            <div>- 핀크스 의전 담당 매니저 케어</div>
            <div>- 어메니티, 미니바 제공</div>
            <div>- F&B 세트 제공</div>
          </div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">메인 프로그램</div>
          <div class="subDatail">
            <div>1. 오션투어 : 아름다운 경관이 펼쳐진 제주 남서부 대정 앞바다 투어 (돌고래 자생지)</div>
            <div>
              2. 송악산 투어 : 산방산 남쪽 바다를 수놓는 송악산 일대의 형제섬, 가파도, 마라도의 비경이 펼쳐진 제주 남쪽
              바다 투어
            </div>
            <div>
              3. 차귀도 투어 : 차귀도의 수려한 자연경관과 깎아놓은 듯한 해안절벽, 기암괴석의 절경이 펼쳐진 제주 서쪽
              바다 투어
            </div>
          </div>
          <div class="rowSpace"></div>
          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>옵션</td>
                <td>시간</td>
                <td>최대인원</td>
                <td>소요시간</td>
                <td>요금(2인기준)</td>
                <td>추가옵션</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">오션투어</td>
                <td>70분</td>
                <td rowspan="6">10인</td>
                <td>2.5H</td>
                <td>2,400,000</td>
                <td rowspan="6">
                  <div>※ 인원추가 +100,000 원 / 인</div>
                  <div>※ 선셋 타임 +200,000 원</div>
                </td>
              </tr>

              <tr>
                <td>90분</td>
                <td>3H</td>
                <td>2,700,000</td>
              </tr>
              <tr>
                <td rowspan="2">송악산 투어</td>
                <td>70분</td>
                <td>2.5H</td>
                <td>2,100,000</td>
              </tr>
              <tr>
                <td>90분</td>
                <td>3H</td>
                <td>2,400,000</td>
              </tr>
              <tr>
                <td rowspan="2">차귀도 투어</td>
                <td>60분</td>
                <td>2.5H</td>
                <td>1,700,000</td>
              </tr>
              <tr>
                <td>90분</td>
                <td>3H</td>
                <td>2,000,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="rowSpace"><hr /></div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">추가옵션</div>

          <GallerySlideCarousels :slides="addition_slides" :slide-count="3" :thumb-slide-width="50" />

          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>구분</td>
                <td colspan="2">옵션(2인기준)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>150,000</td>
                <td>수테라피, 사격, 오모로비짜 스파(40분)</td>
                <td rowspan="5">※ 3인 이상 시 전화 문의</td>
              </tr>
              <tr>
                <td>200,000</td>
                <td>메디컬 스파(바디)</td>
              </tr>
              <tr>
                <td>350,000</td>
                <td>오모로비짜 스파(90분)</td>
              </tr>
              <tr>
                <td>500,000</td>
                <td>스냅(요트)</td>
              </tr>
              <tr>
                <td>플라워 세팅</td>
                <td>300,000 / 500,000 / 700,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">기타사항</div>
          <div class="subDetail">
            <div>※ 5세 이상부터 1인 요금이 적용됩니다.</div>
            <div>※ 경우에 따라 미성년자의 경우에는 보호자 동반이 필요할 수 있습니다.</div>
            <div>※ 단순 고객변심에 의한 취소/환불은 업체 위약 규정에 따라 적용됩니다.</div>
            <div>
              - 7일 전 위약금 0%, 6일 전 ~ 5일 전 30%, 4일 전 50%, 3일 전~2일 전(~17시) 70%, 2일전(17시~) ~ 당일 100%
              위약금 부과
            </div>
            <div>※ 천재지변 및 업체의 운영 상 부득이한 상황으로 취소 시, 위약 규정이 적용되지 않습니다.</div>
            <div>※ 여행자보험 포함 상품입니다.</div>
            <div>※ 요금표 외 기타 인원은 전화 문의</div>
          </div>
        </div>
        <div class="rowSpace"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GallerySlideCarousels from '@/components/GallerySlideCarousels';

export default {
  name: 'luxuryyacht',
  data() {
    return {
      main_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage01.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage01-2.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage01-3.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage01-4.jpg')},
      ],
      addition_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage07.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage08.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage09.jpg')},
      ],
    };
  },
  components: {GallerySlideCarousels},
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '럭셔리요트'});
  },
};
</script>

<style scoped lang="scss">
td {
  vertical-align: middle;
}
.top {
  padding-top: 11rem;
}
.mainTitle {
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: -0.1rem;
}
.mainTitleLine {
  width: 10%;
  > hr {
  }
}
.mainIntro {
}
.buttonArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .resserve-button {
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: #524a3d;
    color: #fff;
  }
  .prev-button {
    justify-content: center;
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: white;
    color: #000;
    border: 1px solid black;
  }
}
.subContainer {
  width: 100%;
}
.mainInfo {
  padding: 1rem;
  width: 100%;
  height: 100%;
  > div {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
.serviceArea {
  padding: 1rem;
  > span {
    font-size: 0.8rem;
  }
}
.subTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.subDetail {
  font-size: 0.9rem;
  padding-left: 1rem;
}
.subTable {
  > thead {
    background-color: #eaeaea;

    > tr > td {
      text-align: center;
      font-weight: 600;
    }
  }
  > tbody {
    > tr > td {
      text-align: center;
      font-size: 0.9rem;
    }
  }
}

.rowSpace {
  width: 100%;
  height: 2rem;
  > hr {
  }
}

.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/pleisure/pleisure_headerimage.jpg');
}

@media (max-width: 992px) {
  .top {
    padding-top: 5rem;
  }

  .headerimage {
    display: none;
  }
}
</style>
